.bg_img{
    /* background-image: url('../../Assets/Images/Svg/bgImg.svg'); */
    /* background-repeat: repeat-y;
    background-size:cover ;
    height: calc(100% - 50px); */
  background-color: #fff9f8;
min-height: calc(100vh - 50px);
    padding: 0px 10px;
}
.container{
    /* height: 100vh; */
    min-height: 100vh;
}