.container {
  width: 95%;
  padding-top: 20px;
  margin: 0 auto auto 0px;
}
.container .head {
  margin-bottom: 15px;
  height: 30px;
  display: flex;
  text-align: center;
}
.container .line {
  width: 3px;
  height: 90%;
  background-color: var(--btnClr);
  margin-right: 10px;
  border-radius: 5px;
}
.container .heading {
  font-family: var(--fontRegular);
  font-size: var(--textXXl);
  font-weight: var(--fontweight);
}
.container .content {
  font-family: var(--fontRegular);
  position: relative;
  display: flex;
  background-color: var(--whiteColor);
  box-shadow: var(--rgba(0, 0, 0, 0.1));
  padding: 20px;
  border-radius: 7px;
}
.container .content .child2,
.child3 {
  font-size: var(--textDefault);
  margin-top: 25px;
}
.savebtn button {
  height: 42px;
  width: 120px;
  margin: 10px 0px !important;
}

/* .content{
    width: 100%;
    background-color: red;
    padding: 25px;
    margin-bottom: 10px;
    border: var(--inputBorder);
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
} */
/* profile */
.container {
  width: 1120px;
  max-width: 100%;
  /* margin: 0px auto 10px 20px; */
}
.container .head {
  margin-bottom: 15px;
  height: 30px;
  display: flex;
  text-align: center;
}
.container .line {
  width: 3px;
  height: 90%;
  background-color: var(--btnClr);
  margin-right: 10px;
  border-radius: 5px;
}
.container .heading {
  font-family: var(--fontRegular);
  font-size: var(--textXXl);
  font-weight: var(--fontweight);
}
.container .mainContainer {
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 7px;
  border: var(--inputBorder);
  box-shadow: 0px 1.8250728845596313px 3.6501457691192627px 0px
    rgba(0, 0, 0, 0.1);
}

.containerLayer{
  border: var(--inputBorder);
  box-shadow: 0px 1.8250728845596313px 3.6501457691192627px 0px
    rgba(0, 0, 0, 0.1);
}
.profileImg {
  border: var(--inputBorder);
  border-radius: 8px;
  padding: 6px;
  width: 250px;
  max-width: 100%;
  margin: auto;
  position: relative;
}
.profileImg img {
  border-radius: 8px;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}
.closed_sec {
  display: flex;
  width: 100%;
  justify-content: end;
}
.child4 img {
  background-color: var(--btnClr);
  padding: 13px;
  border-radius: 5px;
}
.container .child2 {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.same {
  display: flex;
  align-items: center !important;
  font-family: var(--fontRegular);
  margin-bottom: 15px;
  flex-wrap: wrap;
}
.same p:nth-child(2) {
  font-size: 17px;
  font-weight: 500;
  align-self: end;
  margin-left: 5px;
}
.same p:nth-child(1) {
  font-size: 18px;
  font-weight: 500;
}
.upload_image_container {
  margin-top: 20px !important;
  margin: auto;
  width: fit-content;
}
.upload_image {
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 50px;
  border: var(--inputBorder);
  cursor: pointer;
  width: 190px;
}
.upload_image p {
  font-family: var(--fontRegular);
}
.upload_icon {
  cursor: pointer;
  margin-right: 20px;
  width: 40px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  background-color: rgb(231, 223, 223);
}
.infotext {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #878484;
}
.infotext span {
  font-size: 13px;
  margin-right: 5px;
}

/* My Profile Styles */
.myProfileContainer{
  background: linear-gradient(130deg, #ffddd0a8, #f9f9f9);
  padding: 20px;
  border-radius: 10px;
  width: fit-content;
  box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.1);
}
.myProfileImg{
  width: fit-content;
  max-width: 250px;
  position: relative;
}
.myProfileImg img{
  max-width: 100%;
  border-radius: 4px;
}

.myProfileTextContainer{
  padding: 5px 0px;
  /* padding:5px 30px !important; */
  

}
.myProfileTextContainer>p:nth-child(1){
  font-size: 19px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.9) !important;
  line-height: 18px !important;
}

.myProfileTextContainer>p:nth-child(2){
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #f25922 !important;
}

.myProfileAbout{
  padding: 0px 25px;
  /* padding: 5px 0px; */

}

.myProfileAbout>p:nth-child(2){
  font-size: 19px !important;
  font-weight: 600 !important;
  color: #5d5d5d !important;
  padding-bottom: 2px !important;
}
.myProfileAbout>p:nth-child(2)::after{
  content: "";
  width: 40px;
  height: 3px;
  background-color: #878484;
  display: block;
border-radius: 100px;
}
.myProfileAboutTextContainer{
  padding-bottom: 5px;
}
.myProfileAboutTextContainer>p:nth-child(1){
  font-size: 17px !important;
  font-weight: 600 !important;
  color: #3c3c3c !important;
  min-width: 235px;
}
.myProfileAboutTextContainer>p:nth-child(3){
  padding-left: 12px !important;
}

.bounceInTop {
	-webkit-animation: bounce-in-top 1.1s both;
	        animation: bounce-in-top 1.1s both;
}


@media (max-width:500px) {
  .myProfileAboutTextContainer{
    display: block !important;
  }

  .myProfileTextContainer{
    padding: 10px 0px !important;
  }
  .myProfileAbout{
    padding: 10px 0px !important;
  }
  .textContentDivider{
    display: none !important;
  }
}

@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}


@media (max-width: 1200px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .leftcontainer {
    max-width: 300px;
  }
  .profileImg {
    width: 380px;
  }
}

@media (max-width: 575px) {
  .child4 {
    margin-top: 10px;
  }
  .container {
    padding: 20px 0px !important;
  }
}
