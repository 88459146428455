/* button  */

/* .btn button {
  border: none;
  width: 100%;
  background-color: var(--btnClr);
  color: var(--whiteColor);
  border-radius: 5px;
  padding: 10px 5px;
  font-family: var(--fontRegular);
  font-size: var(--textSm);
  font-weight: var(--fontWm);
} */
.btn button {
  border: none;
  width: 100%;
  background-color: var(--btnClr);
  color: var(--whiteColor);
  padding: 10px 5px;
  font-family: var(--fontRegular);
  font-size: var(--textSm);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px;
  position: relative;
  transition: 0.5s;
  /* transition-property: border-radius; */
}

.btn button:hover {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5) !important;
  border-radius: 100px !important;
}
/* &:hover */

/* input  */

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  position: relative;
}
.container .star,
.dropdown .star {
  color: var(--dangerColor);
}

.title {
  color: var(--blackColor);
  font-family: var(--fontRegular);
  font-size: var(--textSm1);
  margin-bottom: 5px !important;
  font-weight: var(--fontWm);
}
.errorTxt {
  color: var(--dangerColor);
  font-size: var(--errorTxt);
  margin-bottom: 0px;
  /* position: absolute; */
}
/* .container .errorTxt {
  position: absolute;
  bottom: -14px;
} */

.inputBox input {
  height: 42px;
  padding: 0px 10px 0 10px;
  background-color: transparent;
  border: none !important;
  outline: unset !important;
}
.inputBox .passwordIcon {
  width: 13%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.passwordIcon img {
  width: 20px;
  height: 20px;
}
.categorizationDeleteIcon {
  background-color: rgb(231, 228, 228);
  width: 13%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.categorizationDeleteIcon img {
  width: 15px;
  height: 15px;
}
.inputBox {
  display: flex;
  width: 100%;
  height: 42px;
  margin-bottom: 7px;
  border: var(--inputBorder);
  background: var(--inputBg);
  border-radius: 8px;
}
.inputBox input::placeholder {
  font-size: 14px;
}

.container .inputBox:hover {
  border: 1px solid #f25922 !important;
}

/* dropdown*/

.dropdown .txt {
  color: var(--blackColor);
  font-family: var(--fontRegular);
  font-size: var(--textSm1);
  margin-bottom: 5px !important;
  font-weight: var(--fontWm);
}

.addBtn {
  padding: 5px 12px;
}
.addBtn button {
  padding: 5px 15px;
  border: none;
  border-radius: 5px 5px;
  background-color: #878484;
}
.addBtn:hover {
  background: rgba(0, 0, 0, 0.04);
}
/* loader  */

.loaderParent {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
}

.loader {
  border: 5px solid rgba(68, 66, 66, 0.5);
  border-radius: 50%;
  border-top: 5px solid #f25922;
  width: 35px;
  height: 35px;
  animation: spin 2s linear infinite !important;
  align-self: center;
  -webkit-animation: spin 2s linear infinite !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.paginationsizedropdown {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  /* margin-right: 25px; */
}
.paginationtxt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
  align-items: center;
}
.paginationtxt > div:nth-child(1) {
  margin-right: 10px;
}
.paginationtxt > div:nth-child(2) {
  margin-left: auto;
}
.dropdowndisplay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdowndisplay p {
  font-family: var(--fontRegular);
  margin-right: 13px;
}
.paginationdropdown {
  border: 1px solid grey;
  margin-left: 5px;
  background: transparent;
  font-weight: 600;
}
.showingitemtxt {
  margin-left: 5px;
  display: flex;
}
.textparagraph {
  margin-right: 3px;
}
.strongboldtxt {
  margin-left: 3px;
  margin-right: 3px;
}

/* infotext */

.infotext {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #878484;
}
.infotext span {
  font-size: 13px;
  margin-right: 5px;
}

/* update cancel actions  */

.modalbtn button {
  height: 42px;
  margin: 10px 0px !important;
}
.ReactApexChart {
  background-color: white;
  box-shadow: 0px 1px 4px 1px #0000001a;
  margin-bottom: 10px;
  padding: 10px 0px 20px;
  /* min-height: 400px; */
  width: 600px;
  border-radius: 8px;
  /* display: flex; */
}
.ReactApex {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
}
.barchartdisplay {
  display: flex;
  justify-content: space-between;
}

.barchartHeader {
  font-weight: bold !important;
  font-size: 22px !important;
  padding-bottom: 10px !important;
}
.legendContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.legendContainer > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.legendContainer .name {
  font-size: 12px;
}
/* DashboardCardTextContent */

.cardTextContainer {
  display: flex;
  align-items: center;
}
.cardTextContainer > button:nth-child(1) {
  margin-right: 10px;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardTextContainer img {
  width: 22px;
}
.textContainer {
  display: flex;
  flex-direction: column !important;
  justify-content: center;

  align-items: center;
}
.textContainer > p:nth-child(2) {
  font-size: calc(0.3vw + 11px) !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 0px !important;
}
.textContainer > p:nth-child(1) {
  font-size: calc(0.3vw + 19px) !important;
  font-weight: 700 !important;
  padding-bottom: 0px !important;
}

/* Image Item */

.actionBtn > img {
  animation: fadeUp 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  opacity: 0;
}
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .modalbtn {
    margin-top: 20px !important;
  }
}
@media (max-width: 575px) {
  .barchartdisplay {
    display: block;
    /* justify-content: space-between; */
  }
  .ReactApexChart {
    background-color: white;
    box-shadow: 0px 1px 4px 1px #0000001a;
    margin-bottom: 10px;
    padding: 10px 00px;
    min-height: 200px;
    width: 400px;
    /* display: flex; */
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .cardTextContainer > button:nth-child(1) {
    width: calc(1vw + 25px) !important;
    height: calc(1vw + 25px) !important;
  }

  .cardTextContainer img {
    width: 17px;
  }
}
