.container1 {
  background: var(--menuBg);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

/* Login-Page */
.container2 {
  display: flex;
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  height: auto;
  max-width: 1100px;
}
.container2 .logo {
  text-align: center;
  margin-bottom: 21px;
}
.logo img {
  width: 50%;
  height: 10%;
}
.container2 .leftcontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.leftcontent .title {
  font-family: var(--fontRegular);
  font-size: var(--textXl2);
  font-weight: var(--fontweight);
}

.container2 .rightcontent {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  background: #ffff;
  padding: 25px 40px;
  border-radius: 10px;
  margin: auto;
}
@media (max-width: 575px) {
  .container2 {
    padding: 10px 10px;
  }
  .captcha {
    width: 35%;
  }
  .inputfield2 .captcha img {
    width: 100% !important;
    object-fit: contain;
  }
  .container2 .rightcontent {
    width: 100%;
    padding: 20px 10px;
    box-shadow: none;
    border: none;
    margin-left: 0px !important;
  }

  .inputContainer input::placeholder {
    font-size: 14px !important;
  }
  .inputfield2 input::placeholder {
    font-size: 14px !important;
  }
}
