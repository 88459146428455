* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--fontRegular);
}

:root {
  --fontRegular: "Poppins Regular";
  --fontSemibold: "Poppins SemiBold";
  --fontMedium: "Poppins Medium";
  --fontBold: "Poppins Bold";
  --fontExtraBold: "Poppins ExtraBold";
  --fontLight: "Poppins Light";
  --dangerColor: #f14a45;
  --inputBg: rgba(255, 255, 255, 0.9);
  --tableHeadBg: #f2592238;
  --tableBrBottom: 1px solid rgba(0, 0, 0, 0.1);
  --whiteColor: #fff;
  --blackColor: #262626;
  --lowBlackColor: #252525;
  --successColor: #2ab95a;
  --menuBg: #1a2c41;
  --btnClr: #f25922;
  --menuBg2: #fff9f8;
  --cancelBtnClr: rgb(195, 193, 193);
  --menuBg: #1a2c41;
  --capimgbg: rgba(217, 217, 217, 0.5);
  --logindesccolor: rgba(0, 0, 0, 0.7);
  --inputBorder: 0.5px solid rgba(0, 0, 0, 0.25);
  --inputBg: rgba(255, 255, 255, 0.9);
  --shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  --inputBodyClr: rgba(38, 38, 38, 0.9);
  --gantchartBorder: 1px solid #ffffff;
  --dropdownBg: #ebebeb;
  --dropdownClr: #555555;
  --textxxs: 11px;
  --textXs1: 11px;
  --textXs: 14px;
  --textXs2: 14.5px;
  --textSm1: 15px;
  --textS: 15.5px;
  --errorTxt: 12px;
  --textcolor: rgba(38, 38, 38, 0.9);
  --textcolor2: rgba(0, 0, 0, 0.75);
  --textXs2: 14.5px;
  --textSm: 16px;
  --textMd: 18px;
  --textLg: 24px;
  --textXl: 26px;
  --textXl2: 27px;
  --textXXl: 20px;
  --textDefault: 17px;
  --texth6: 19px;
  --xlText: 75px;
  --lgText: 63px;
  --mdText: 48px;
  --fontWm: 500;
  --fontWs: 400;
  --fontweight: 600;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: none !important;
}
@font-face {
  font-family: "Poppins Bold";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Bold.ttf") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins Medium";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Medium.ttf") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins Regular";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Regular.ttf") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins ExtraBold";
  src: url("../src/Assets/Fonts/Poppins/Poppins-ExtraBold.ttf") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins Light";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Light.ttf") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("../src/Assets/Fonts/Poppins/Poppins-SemiBold.ttf") format("woff");
  font-display: swap;
}

img,
svg {
  cursor: pointer;
}
.appcontainer {
  max-width: 1800px;
  margin: auto;
}
.primarycard {
  background-color: #ffffff;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 20px !important;
}

.placeHolder ::placeholder {
  color: #222222 !important;
  opacity: 1 !important;
}
.placeHolder ::-ms-input-placeholder {
  color: #222222 !important;
  opacity: 1 !important;
}

/* Profile dropdown */

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: none !important;
  border-color: black !important;
}
/* .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active { */
p {
  margin-bottom: 0 !important;
}

/*offcanvas*/

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: none !important;
  --bs-btn-border-color: none !important;
  --bs-btn-hover-color: none !important;
  --bs-btn-hover-bg: none !important;
  --bs-btn-hover-border-color: none !important;
  --bs-btn-focus-shadow-rgb: none !important;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: none !important;
  --bs-btn-active-border-color: none !important;
}
.offcanvas {
  background-color: var(--menuBg) !important;
  color: #fff !important;
}
.offcanvas-title {
  font-size: 15px !important;
}
.btn-close {
  background-color: #fff !important;
  margin-bottom: 20px !important;
  padding: 5px !important;
  margin-right: 1px !important;
  margin-left: auto;
}
.offcanvas-body p {
  margin-top: 10px;
}
.offcanvas-body img {
  margin-top: 15px;
}
.offcanvas.offcanvas-start {
  width: 255px !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: var(--inputBorder) !important;
}
.MuiAutocomplete-endAdornment button {
  border: none !important;
}

/* .css-1xhypcz-MuiStack-root>.MuiTextField-root { */

.dropdownbtn .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  margin-left: 0px !important;
  width: 100% !important;
}

.dropdown-toggle::after {
  margin-top: 10px !important;
}
.dropdownbtn .MuiAutocomplete-inputRoot {
  padding: 7px !important;
  border-radius: 8px !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-width: 0px !important;
}
.MuiTabs-root {
  min-height: 0px !important;
}
/* .activeTab .css-1aquho2-MuiTabs-indicator {
    left: 15px !important;
    width: 50% !important;
    background-color:  rgb(242, 89, 34) !important;
} */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding-bottom: 0px !important;
}
.headerTab .css-1ujnqem-MuiTabs-root {
  min-height: 0px !important;
}
.headerTab .css-1ujnqem-MuiTabs-root button {
  min-height: 0px !important;
  padding: 5px 16px !important;
}

.css-1pduc5x-MuiStack-root {
  overflow: hidden !important;
  padding-top: 0px !important;
}

/* React paginate Custom Styles */

.bg_orange {
  background-color: var(--secondaryColor) !important;
}
.page-item.active .page-link {
  background-color: var(--btnClr) !important;
  width: 35px !important;
  height: 33px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--whiteColor) !important;
}
.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  padding: 7px 4px 7.5px 5px !important;
}

.MuiInputBase-root {
  /* border: var(--inputBorder) !important; */
}
.dropbr8 > div > div {
  border-radius: 8px !important;
}
/* table */

.table-responsive td {
  font-family: var(--fontRegular) !important;
  font-weight: var(--fontWs) !important;
  font-size: var(--textSm1) !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: none !important;
  border-bottom: none !important;
  text-align: left !important;
  padding: 7px 18px !important;
}
.table-responsive th {
  font-family: var(--fontRegular) !important;
  font-weight: 600 !important;
  font-size: var(--textSm) !important;
  /* color: var(--whiteColor) !important; */
  padding: 10px 18px !important;
  border-bottom: none !important;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: none !important;
  text-align: left !important;
}

.table-responsive {
  background: white;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.05);
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 10px;
}

th:first-child {
  border-top-left-radius: 6px;
}
th:last-child {
  border-top-right-radius: 6px;
}

.globalModalmaxWidth .MuiPaper-root {
  max-width: 750px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 8px !important;
}
.css-4jnixx-MuiStack-root {
  padding-top: 3px !important;
}
.css-5gretd-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 5px !important;
}

.childbtnMt-0 > div > button {
  margin-top: 0px !important;
}

.errTxt {
  color: var(--dangerColor) !important;
  font-size: var(--errorTxt) !important;
}

.cardShadows {
  background-color: white;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.fieldBr8 .MuiInputBase-root {
  border-radius: 8px !important;
}

.textarea {
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding: 7px;
  border: 0.5px solid rgba(135, 132, 132, 0.7);
  border-radius: 8px;
  margin-bottom: 10px;
  outline: unset;
  resize: none;
}

/* Chart's Custom Tooltip */

.custom-tooltip {
  background-color: #fff;
  /* padding: 10px; */
  font-family: Arial, sans-serif;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 180px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.tooltip-header {
  background-color: #eceff1;
  color: #000000;
  padding: 10px;
  font-weight: 600;
}
.tooltip-header > span {
  color: rgba(0, 0, 0, 0.6);
}
.tooltip-marker {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin-right: 10px;
}

.tooltip-text {
  padding: 10px;
  display: flex;
  align-items: center;
}
.tooltip-text span {
  font-weight: 700;
  padding-left: 3px;
}

.tooltip-text > div:nth-child(2) {
  word-wrap: break-word;
}

/* .css-1ygcj2i-MuiTableCell-root {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500 !important;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87) !important;
} */
@media (min-width: 992px) {
  .globalmodalminWidth > div > div:nth-child(1) {
    min-width: 750px;
  }
  .logoutmodalminWidth > div > div:nth-child(1) {
    min-width: 450px;
  }

  .globalModallg > div > div:nth-child(1) {
    min-width: 950px !important;
  }
}
@media (min-width: 575px) {
  .logoutmodalminWidth > div > div:nth-child(1) {
    min-width: 450px;
  }
  .sm-px-35 {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (max-width: 992px) {
  .dropdown,
  .dropdown-center,
  .dropend,
  .dropstart,
  .dropup,
  .dropup-center {
    display: none !important;
  }
}

/* select mui  */
/* .MuiSelect-root {
  background-color: red !important;
} */

.ZoomAnimation {
  scale: 0;
  animation: ZoomAnime 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes ZoomAnime {
  0% {
    scale: 0;
  }
  100% {
    scale: 1;
  }
}

.FlowAnimation {
  width: 0;
  opacity: 0;
  animation: FlowAnime 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes FlowAnime {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

.ant-input-number-wrapper {
  height: 40px !important;
}
::-webkit-scrollbar {
  width: 4px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 100px;
  border: 1px solid rgb(202, 196, 196);
  z-index: 1;
}

::-webkit-scrollbar-thumb:active {
  border: 2px solid rgb(202, 196, 196);
}
::-webkit-scrollbar-track {
  background-color: rgb(202, 196, 196);
  border-radius: 100px;
}
