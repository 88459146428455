/* all popup same head, close and button*/
.popup .popup_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.infotext {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #878484;
}
.infotext span {
  font-size: 13px;
  margin-right: 5px;
}
.modalbtn button {
  height: 42px;
  margin: 10px 0px !important;
}
.AddEditCancelBtns {
  width: 92%;
  margin: auto;
}
.popup {
  width: 95%;
  margin: 10px auto;
  font-family: var(--fontRegular);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup .line {
  width: 3px;
  height: 80%;
  background-color: var(--btnClr);
  margin-right: 10px;
  border-radius: 5px;
}
.popup .popup_head_left {
  font-size: var(--textMd);
  font-weight: var(--fontWxl);
  display: flex;
  align-items: center;
}
.popup .Heading {
  font-size: var(--textXXl);
  font-weight: var(--fontweight);
  font-style: var(--fontRegular);
}
.close {
  cursor: pointer;
  /* position: absolute;
  right: 20px;
  top: 20px; */
  padding: 5px;
  border-radius: 5px;
  border: 1.5px solid #878484;
  width: 20px;
  height: 20px;
}
.cancelbtn {
  background-color: rgba(135, 132, 132, 1);
}
/* all popup same head  and close*/
/* confirmation  modal  */

.confirmModal .confirm_Btns {
  width: 100%;
  display: flex;
  margin: 25px auto;
  gap: 40px;
  justify-content: center;
  align-items: center;
}
.confirmModal .text {
  font-size: var(--textSm1);
  font-weight: var(--fontWs);
  text-align: center;
}
.confirmModal .deleteIcon {
  text-align: center;
  margin: 20px 0;
}

/* title */

.title {
  color: var(--blackColor) !important;
  font-family: var(--fontRegular) !important;
  font-size: var(--textSm1) !important;
  margin-bottom: 5px !important;
  font-weight: var(--fontWm) !important;
}

/* textarea input */

.text_area {
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding: 7px;
  border: 0.5px solid rgba(135, 132, 132, 0.7);
  border-radius: 8px;
  margin-bottom: 10px;
  outline: unset;
  resize: none;
}
.text_areaError {
  position: absolute !important;
  color: var(--dangerColor) !important;
  font-size: var(--errorTxt) !important;
  bottom: -5px;
}
.text_area:hover {
  border: 1px solid #f25922;
}
textarea::placeholder {
  font-size: 14px;
}
.textareacontainer p:not(.text_areaError) {
  color: var(--blackColor);
  font-family: var(--fontRegular);
  font-size: var(--textSm1);
  margin-bottom: 5px !important;
  font-weight: var(--fontWm);
}

/* Add Device  */

.add_device {
  width: 90%;
  margin: 5px auto;
}
.viewModalDisplay {
  display: flex;
}
.viewliststyle {
  list-style: none;
}
/* Add Model */

.addModel,
.AddDeviceModalLink,
.Addsubcription,
.Categarization {
  width: 92%;
  margin: 10px auto;
}
.allFiledNames {
  border-top: var(--inputBorder);
}
.macBorder > div > div {
  border: var(--inputBorder) !important;
}
.AddModelDesc p {
  color: var(--blackColor);
  font-family: var(--fontRegular);
  font-size: var(--textSm1);
  margin-bottom: 5px !important;
  font-weight: var(--fontWm);
  position: relative;
}
.AddModelDesc .text_area {
  padding: 5px;
}
.AddBoardHead p {
  font-family: var(--fontBold);
}
.rowContainer {
  align-items: center;
  border-bottom: var(--inputBorder);
}
.errorTxt {
  color: var(--dangerColor) !important;
  font-size: var(--errorTxt) !important;
  margin-bottom: 0px !important;
  bottom: 95px;
}
.add_device .heading,
.add_mainmodel .heading,
.vieworg .heading {
  display: flex;
  font-weight: 500;
  margin-bottom: 8px;
}
.add_device .heading p,
.add_mainmodel .heading p {
  min-width: 129px;
}
.vieworg .heading p {
  min-width: 176px;
}
.add_device .heading span,
.add_mainmodel .heading span,
.vieworg .heading span {
  word-break: break-word;
  font-weight: 400;
  margin-left: 10px;
}

/* add suppliers  */
.add_supplier {
  width: 90%;
  margin: 5px auto;
}
/* addboradbatchcomponents  */

.icons {
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 15px;
}

.icons img {
  height: 30px;
  cursor: pointer;
  padding: 8px;
  background-color: var(--inputBg);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  text-align: center;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
}
.component,
.addDeviceBatch,
.AddPartNumberQuestion {
  width: 90%;
  margin: auto;
}
.addDeviceBatch,
.AddPartNumber {
  width: 95%;
  margin: auto;
}
.multipleContent {
  width: 100%;
  padding: 10px 5px;
  border-top: var(--inputBorder);
}
.viewContainer {
  width: 400px;
  margin: auto;
  line-height: 25px;
}
.viewContent {
  display: flex;
}
.catgarizationFields {
  font-family: var(--fontRegular);
  font-size: var(--textXXl);
  font-weight: var(--fontweight);
}
.catgarizationFieldsList {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.viewContent p {
  min-width: 150px;
  font-family: var(--fontSemibold);
}
.viewContent span {
  margin-left: 5px;
}

.AddFirmWare,
.AddBoardSpecification {
  width: 95%;
  margin: auto;
}
.AddSpecificationQuestion {
  width: 90%;
  margin: auto;
}
.filterByOptions div div div {
  padding: 4px 0px 5px;
}
.upload_file input {
  display: none;
}
.upload_file {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  border: var(--inputBorder);
  display: flex;
  align-items: center;
}
.firmwareName {
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.08);
  overflow: auto;
}
.upload_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: 50px;
  margin-right: 20px;
  border-radius: 5px;
  background-color: rgb(226, 223, 223);
  font-family: var(--fontBold);
}
.AddFirmWare .title span {
  color: red;
}
/* board specification defination page  */
.catgoryDropDown {
  margin-top: 20px;
  margin-bottom: 30px;
}
.subscriptonUpdate {
  display: flex;
}
.buttonMargin {
  margin-right: 10px;
}
@media (min-width: 1300px) {
  .editbtn {
    margin-top: 15px !important;
  }
}
@media (max-width: 1200px) {
  .editbtn {
    margin-top: 20px !important;
  }
}

@media (max-width: 575px) {
  .add_device .heading,
  .add_mainmodel .heading,
  .vieworg .heading {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 992px) {
  .AddDeviceModalLink {
    min-width: 650px !important;
    overflow-y: auto;
  }
}
.isCheckboxdisplay {
  display: flex;
}
.firmwareUpload {
  width: 200px;
  height: 47px;
  overflow-y: scroll;
}
.deviceCodeTitle {
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-color: #f25922;
  text-decoration-thickness: 2px;
}
.deviceListcontanier {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  flex-wrap: wrap;
}
.deviceListcontanier p {
  background-color: powderblue;
  padding: 5px 10px;
  border-radius: 6px;
}
