.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  /* margin: 20px 0; */
}
.header_left {
  font-size: var(--textMd);
  /* font-weight: var(--fontWxl); */
  display: flex;
  align-items: center;
}
.header_left .heading {
  font-family: var(--fontRegular);
  font-size: var(--textXXl);
  font-weight: var(--fontweight);
  margin-left: 10px;
}
.header_left .line {
  width: 3px;
  /* height: 70%; */
  background-color: var(--btnClr);
  margin-right: 10px;
  border-radius: 5px;
  position: absolute;
  /* margin-top: 50%; */
  height: 30px;
  animation: 0.5s countboxline forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0;
}

@keyframes countboxline {
  0% {
    transform: translateY(80px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.header_right {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.searchimagebox {
  margin-right: 23px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid gainsboro;
  box-shadow: 0px 0px 0px 0.2px;
}
.searchimage {
  width: 20px;
  height: 20px;
}

.dropDown {
  width: 180px;
  margin-right: 20px;
  /* margin-top: -30px; */
}
@media (max-width: 575px) {
  .header_left .heading {
    font-size: 20px;
    /* width: 10%; */
  }
  .header {
    display: flex;
    flex-wrap: wrap;
  }
  .header_right {
    width: 100%;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
  }
  .header_left {
    margin-bottom: 10px;
  }
  /* .header_left .line {
    height: 90%;
  } */
}
.warning_icon {
  cursor: pointer;
  width: 35px;
  height: 35px;
  animation-name: notification;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 3s;
  cursor: pointer;
  margin-right: 20px;
}
