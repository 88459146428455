.title {
  color: var(--blackColor);
  font-family: var(--fontRegular);
  font-size: var(--textSm1);
  margin-bottom: 5px !important;
  font-weight: var(--fontWm);
}
.subbtn button {
  height: 42px;
  width: 120px;
  margin: 10px 0px !important;
}
.ImMonth {
  font-size: 12px;
}
.noData {
  font-family: var(--fontRegular);
  text-align: center;
  width: 100%;
  height: 250px;
}
.textClose {
  cursor: pointer;
}
.nodatafound {
  text-align: center;
  width: 100%;
}
.pagination {
  margin: 30px 30px 40px 30px !important;
}
.serachContainer {
  width: 98%;
  margin: 10px auto;
  border-radius: 8px;
  padding: 10px;
  box-shadow: var(--shadow);
  background-color: white;
}
.alerticon {
  border-radius: 25px;
  /* width: 20px;
    height: 20px; */
  background: none !important;
  border: none !important;
  /* filter: blur(0.5px); */
  background-color: green;
  /* margin-top: 6px; */
  animation: 2s alerticon infinite;
}
@keyframes alerticon {
  0% {
    opacity: 0;
    /* filter: blur(1px); */
  }
  100% {
    /* filter: blur(0); */
    opacity: 1;
  }
}
@keyframes accordion {
  0% {
    height: 0;
    opacity: 0;
  }
  90% {
    height: 150px;
  }
  100% {
    height: auto;
    opacity: 1;
  }
}
/* all tables same css */
.AllTable {
  width: 98%;
  margin: 10px auto;
}

table {
  width: 100%;
  font-family: var(--fontRegular);
}

table thead {
  background-color: var(--tableHeadBg);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
}

table th {
  font-size: var(--textSm);
  font-weight: 600 !important;
  color: var(--blackColor) !important;
  padding: 10px;
}

tbody td:first-child {
  padding-left: 25px;
}

table td {
  font-size: var(--textSm1);
  font-weight: var(--fontWs);
  color: var(--inputBodyClr);
  padding: 10px;
}

table th:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-left: 20px;
}

table th:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-right: 60px;
}

tr:nth-child(1) {
  border-bottom: none !important;
}

tr:nth-child(3n) {
  background-color: rgb(242 89 34 / 3%) !important;
  border-radius: 6px;
}
tr:nth-child(3n + 1) {
  border-bottom: 1px solid rgb(242 89 34 / 3%);
}

table .icons {
  display: flex;
  gap: 15px;
}

table .icons img {
  width: 32px;
  height: 32px;
  cursor: pointer;
  padding: 8px;
  background-color: var(--inputBg);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  text-align: center;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
}
table .icons .link_icon {
  width: 35px;
  height: 35px;
  padding: 5px !important;
}
/* table .icons img:first-child,
.icons img:nth-child(2) {
  margin-right: 10px;
}
table .icons img:first-child,
.icons img:nth-child(3) {
  margin-right: 10px;
} */
.orgheader {
  min-width: 260px !important;
}

/* pagenation */
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.pagination li {
  list-style-type: none !important;
}
/* Server Detail */
.ServerHeading {
  display: flex;
  justify-content: space-between;
  width: 97%;
  margin: auto auto;
  position: relative;
}
.server_details {
  width: 95%;
  margin: auto auto;
}
.server_details .details {
  line-height: 35px;
  font-family: var(--fontRegular);
  display: flex;
}

.server_details .details p {
  font-size: 17px;
  font-weight: bold;
}
.server_details .details strong {
  margin-left: 5px;
}

.server_details .details span {
  font-size: 17px;
  margin-left: 10px;
}

.CustomSwitch {
  position: absolute;
  right: 70px;
  top: -55px;
}
.serverviewicon {
  margin: -2px 0 0px 10px;
}

/* borad batch details list  */
.printer {
  width: 33px;
  height: 33px;
}
.boradBatchDetails {
  width: 97%;
  margin: auto;
}
#machine_Detail {
  background-color: white;
  padding: 10px;
  margin-top: 15px;
  border-radius: 8px;
  box-shadow: var(--shadow);
  line-height: 30px;
  /* display: flex; */
  /* flex-direction: column; */
}

.details {
  display: flex;
  max-width: 100%;
  word-break: break-all;
}
.boradBatchDetails .details p {
  font-family: var(--fontMedium);
  min-width: 175px;
  font-size: 16px;
  margin-right: 5px;
}
.boradBatchDetails .details span {
  font-size: 16px;
  margin-left: 4px;
}
/* suppliers  */

.suppliers,
.BoardSpecification {
  padding-top: 10px;
}

/* //firmwaremanagemnet  */

.modalStyle {
  min-width: 500px !important;
  background-color: red !important;
}
/* //main model  */
@media (min-width: 1200px) {
  .serachContainer {
    /* animation: accordion 0.5s forwards;
    animation-fill-mode: forwards;
    height: 0; */
    transition: 1s;
  }
}

@media (max-width: 1200px) {
  .orgtable {
    width: 1580px !important;
    overflow-y: auto;
  }
  .table {
    width: 1500px !important;
    overflow-y: auto;
  }
}

@media (max-width: 1200px) {
  .orgtable {
    width: 1580px !important;
    overflow-y: auto;
  }
  .table {
    width: 1500px !important;
    overflow-y: auto;
  }
}
@media (max-width: 992px) {
  .table {
    width: 1200px !important;
    overflow-y: auto;
  }
}

@media (max-width: 767px) {
  .table {
    width: 1200px !important;
    overflow-y: auto !important;
  }
}

@media (max-width: 575px) {
  .table {
    width: 1100px !important;
    overflow-y: auto !important;
  }
}

.tableChange {
  display: flex;
  cursor: pointer;
  font-size: var(--textXXl);
  font-weight: var(--fontWs);
  font-family: var(--fontRegular);
  margin-left: 23px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.tabContent {
  text-transform: capitalize !important;
  font-family: var(--fontRegular) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 23px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}
.activeTab {
  color: #000000 !important;
}
.removedevice_icon {
  width: 33px !important;
  height: 32px;
  padding: 5px !important;
}
