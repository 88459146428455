.headerlabel{
    font-size: 18px !important;
    font-weight: 600 !important;
    padding-bottom: 15px !important;
}
.placeHolder ::placeholder{
    color : #222222 !important;
    opacity: 1 !important;
}
.placeHolder ::-ms-input-placeholder {
    color : #222222 !important;
    opacity: 1 !important;

}

::-webkit-file-upload-button , .choosefile{
    background-color: rgb(242, 89, 34);
    color : white;
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

::-webkit-file-upload-button:hover , .choosefile:hover{
    background-color: rgb(192, 63, 17);

}

.imageContainer{
    transition: 0.5s;
}

.imageContainer:hover{
    background-color: #f259224a !important;
}

.imageContainer:hover img:nth-child(1), .imageContainer:hover p{
    filter: blur(1px);
}

.actionBtn{
    position: absolute;
   inset: 0;
   display: flex;
   align-items: center;
   justify-content: center;
}

   .actionBtn>img{
    filter: inherit !important;
   }


   .nodata{
    width: 200px;
    max-width: 100%;
    margin: auto;
    display: block;
   }

   .nodataText{
    text-align: center;
    color: rgba(0,0,0,0.5);
    font-size: 14px;
   }