.header {
  width: 100%;
  height: 50px;
  background-color: var(--menuBg);
  position: sticky;
  top: 0;
  z-index: 1;
}
.header .content {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  cursor: pointer;
}
.header .child2 {
  display: flex;
  align-items: center;
  font-size: var(--textS);
  font-weight: var(--fontWm);
  font-family: var(--fontRegular);
}
.header .header_name {
  padding-right: 23px;
}
.header .child3 {
  display: flex;
  font-family: var(--fontRegular);
}
.user_name {
  font-size: var(--textSm1);
  font-weight: var(--fontWm);
  font-family: var(--fontRegular);
}
.user_id {
  font-size: 12px;
  font-weight: var(--fontWs);
  font-family: var(--fontRegular);
}
.nameContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.downArrow {
  margin-left: 7px;
}
.profileLogout {
  display: flex;
}

.user_image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.header .child3 .downArrow {
  margin-left: 7px;
}
.userDropdown {
  background: transparent !important;
  border: none !important;
  margin-top: 21px !important;
}

.tabContent {
  text-transform: capitalize !important;
  font-family: Poppins Regular !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 23px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #fff !important;
}
.perofile_icons {
  display: flex;
  /* padding: 2px 0px; */
}
.perofile_logouticons {
  display: flex;
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.perofile_logouticons img {
  width: 17px;
  height: 17px;
}
.perofile_logouticons {
  padding: 2px 0px;
}
.perofile_logouticons p {
  margin-top: -2px;
}
.dropdown button {
  display: flex !important;
  height: 50px !important;
  margin-top: 0px !important;
}
.dropdown > div {
  padding: 1px 0px !important;
}
.item {
  padding: 12px 15px;
  position: relative;
}
.borderBottom {
  width: 83%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.25);
  margin-top: 45px;
  position: absolute;
  z-index: 10000;
}
.perofile_icons img,
.perofile_logouticons img {
  margin-right: 10px;
}
.perofile_icons p,
.perofile_logouticons p {
  font-size: var(--textXs);
  font-weight: var(--fontWs);
  font-family: var(--fontRegular);
}
.activeTab {
  color: white !important;
}

/* off Canvas content */
.menu {
  display: none !important;
}
.menubar_content {
  display: flex;
  align-items: end;
}
.menubar_icon {
  width: 10%;
}
.menubar_icon img {
  width: 23px;
}
.menubar_content p {
  margin: 13px 0 0 10px;
}
.menubar_dropdown img {
  width: 10px;
  height: 23px;
  margin-left: 10px;
}
.menuIcon img {
  width: 25px;
  height: 25px;
}

@media (max-width: 1100px) {
  .profiledropdown {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .content .userDropdown,
  .content img,
  .content .child2 {
    display: none;
  }
  .menu {
    display: flex !important;
    justify-content: space-between;
    height: 50px;
    text-align: center !important;
    align-items: center !important;
    margin: auto auto;
    width: 95%;
  }
  .sidenav {
    display: block;
  }
  .header .content {
    display: flex !important;
    justify-content: space-between !important;
  }
  .child3 {
    display: flex;
  }
  .child3 p {
    margin-left: 10px;
  }
  .content .child3 {
    display: none;
  }
  .child3 .user_name span {
    display: none;
  }
  .child3 img {
    border-radius: 50%;
    border: 2px solid white;
  }
}
@media (max-width: 767px) {
  .content .userDropdown,
  .content img,
  .content .child2 {
    display: none;
  }
  .menu {
    height: 50px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    text-align: center;
    margin: auto auto;
  }
  .sidenav {
    display: block;
  }
  .header .content {
    display: flex !important;
    justify-content: space-between !important;
  }
  .child3 {
    display: flex;
  }
  .child3 p {
    margin-left: 10px;
  }
  .content .child3 {
    display: none;
  }
  .child3 .user_name span {
    display: none;
  }
  .child3 img {
    border-radius: 50%;
    border: 2px solid white;
  }
}

@media (min-width: 1750px) {
  .dropdown > div {
    transform: translate(-8px, 52px) !important;
  }
}
