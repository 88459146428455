.subHead{
    display: flex;
    flex-wrap: wrap;
    font-size: var(--textSm1);
    font-family: var(--fontRegular);
    font-weight: var(--fontWm);
    justify-content: center;
    width: calc(100% + 20px);
    /* margin-left: 6%; */
    cursor: pointer;
    position: sticky;
    top: 50px;
    z-index: 1;
    margin-left: -10px;
    background-color:  #FFF9F8;;
}
.tabContent {
    text-transform: capitalize !important;
    font-family: Poppins Regular !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 23px !important;
    letter-spacing: 0em !important;
    text-align: left !important;

}
.activeTab {
color: #000000 !important;
}
@media (min-width:768px) and (max-width:1100px){
   .subHead{
    display: none;
   } 
}
@media  (max-width:767px){
    .subHead{
        display: none;
       }
}
