.dashboard {
  font-family: var(--fontRegular);
  width: 99%;
  margin: auto auto;
  padding-top: 10px;
  max-width: 1800px;
  /* max-width: 2200px; */

  margin: auto;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
}
.header_left {
  font-size: var(--textMd);
  display: flex;
  align-items: center;
}
.header_left .heading {
  color: black;
  font-family: var(--fontRegular);
  font-size: 22px;
  font-weight: 600;
}
.header_left .line {
  width: 3px;
  height: 75%;
  background-color: var(--btnClr);
  margin-right: 10px;
  border-radius: 5px;
}
.childs {
  width: 99%;
  margin: auto;
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
  grid-template-columns: 32% 32% 32%;
}

.childs .pieChart {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 1px #0000001a;
  padding: 20px;
}

.firstChild {
  width: 100%;
  
  /* margin: 0 auto 8px auto; */

  /* max-width: 1150px; */
  --bs-gutter-x : 0px !important;
}
.firstChild .counts {
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 1px #0000001a;
  padding: 20px 20px;margin-right: 20px;height: 100%;
}
.counts p:first-child {
  font-size: 30px;
  padding-bottom: 15px;
}
.counts p:last-child {
  font-size: 18px;
}

.errorTxt {
  color: var(--dangerColor);
  font-size: var(--errorTxt);
}
.ReactApex {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterOptions {
  width: 98.5%;
  margin: 0 auto 10px auto;
  display: flex;
  align-items: center;
  justify-content: end;
}
.filterOptions .filter_by_type {
  margin-right: 10px;
  font-family: var(--fontSemibold);
}
.filterOptions .filter_icon_txt {
  width: 25%;
  font-family: var(--fontSemibold);
}
.filterOptions .filtericontxt {
  /* width: 100%; */
  display: flex;
  cursor: pointer;
 justify-content: end;
  font-family: var(--fontSemibold);
}
.filterOptions p img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.filters {
  border-radius: 10px;
  padding: 20px 20px 30px 10px;
  background-color: white;
  box-shadow: 0px 1px 4px 1px #0000001a;
  width: 420px;
  max-width: 100%;
  margin: 0 auto 20px 17px;
}
.filters2 {
  border-radius: 10px;
  padding: 20px 20px 20px 10px;
  background-color: white;
  box-shadow: 0px 1px 4px 1px #0000001a;
  /* width: 900px; */
  max-width: 100%;
  margin: 0 0 20px 0;
}
.filterByOptions {
  display: flex;
  align-items: center;
}
.filterByOptions div div div{
  padding: 4px 0px 5px;
}
.color_and_names {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 100% !important;
  margin-left: 10px;
  flex-wrap: wrap;
}
.color_and_names > div:not(:nth-last-child(1)) {
  margin-right: 8px;
}

.dot {
  width: 11px;
  height: 11px;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
}
.dot_names {
  font-size: 14px;
  cursor: pointer;
}
.countbox{
  display: flex;
  justify-content: space-around;
}
.ongoingcountboxtext{
  display: flex;
  justify-content: center;
  color: rgb(53, 219, 53);
  font-size: 50px;
}
.completecountboxtext{
  color: rgb(221, 40, 40);
  display: flex;
  justify-content: center;
  font-size: 50px;
}
.Deactivecountboxtext{
  color: #ffaf38;
  display: flex;
  justify-content: center;
  font-size: 50px;
}
/* .filtersdatepicker>div{
  min-height: 44px;
} */
.countboxheadingsize{
  font-weight: bold;
  font-size: calc(0.4vw + 13px) !important;
  margin-top: 20px;
  margin-left: 10px;
}
.requestcountbox{
  font-weight: bold;
  font-size: calc(0.4vw + 13px) !important;
  margin-top: 10px;
  margin-left: 10px;
}
.countboxtextsize{
  font-size: 16px !important;
}
.countboxsize{
  /* width: 437px !important; */
}
.imageContainer{
  width: 75px;
  height: 75px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  top: -43px;
  left: 0;
  right: 0;
  margin: auto; */
}
.imageInnerContainer{
  width: 55px;
  height: 55px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border : 2px solid white
}

/* Card Text Container */
.cardTextContainer>p{
  font-weight: 600;
  font-size: 22px !important;}

  .cardTextContainer p{
    padding: 0 !important;
  }

  .cardTextContainer>div>p:nth-child(1){
    font-size: 26px !important;
    font-weight: 700;
  }
  .cardTextContainer>div{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .cardTextContainer>div>p:nth-child(2){
    color: rgba(0,0,0,0.6);
    font-size: 14px;
    font-weight: 500;
  }

/* barchart */
.barchartContainer{
  background: white;
    box-shadow: 1px 0px 10px 1px rgba(0,0,0,0.1);
    border-radius: 8px;
    padding: 20px 30px;
}

.countText{
  font-size: 22 !important;
  font-weight: 600 !important;
  padding: 0px !important;
}
.totalcountdisplay{
  display: flex;
  justify-content:space-between;
  margin-bottom: 20px;
}
.totalRequestcountdisplay{
  display: flex;
  justify-content:space-between;
  cursor: pointer;
}
.Seemorebtn{
  text-align: right;
  /* width: 98%; */
  padding: 0px 35px;
}
.Seemorebtn p{
  font-size: 15px;
  /* color:#f25922; */
  color : #1976d2;
  cursor: pointer;
  font-weight: 500;
}
.Seemorebtn img{
  width: 20px;
}
.totalcountdisplayline{
  width: 3px;
    height: 27px;
    background-color: #f25922;
    margin-right: 10px;
    margin-top: 21px;
    position: absolute;
    animation:0.5s countboxline  forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
    opacity: 0;
}

.searchClearMb0>div{
  margin-bottom: 0px !important;
}

@keyframes countboxline {
  0%{
    transform: translateY(80px);
  opacity: 0;}
  100%{transform: translateY(0px);
  opacity: 1;}
}
@media (max-width: 992px) {
  /* .counts {
    width: 213px;
  } */
  .counts p:first-child {
    font-size: 23px;
  }
  .counts p:last-child {
    font-size: 15px;
  }
  .filters {
    margin: auto;
    width: 98%;
    justify-content: center;
  }
  .childs {
    grid-template-columns: 48% 48%;
  }
}
@media (max-width: 768px) {
  .firstChild {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 20px;
  }
  .counts {
    width: 100%;
  }
  .filters {
    margin: auto;
    width: 98%;
    justify-content: center;
  }
}
@media (max-width: 450px) {
  .filters {
    margin: auto;
    width: 95%;
    justify-content: center;
  }
  .filters2 {
    margin: auto;
    width: 95%;
    justify-content: center;
  }
  .childs {
    grid-template-columns: 95%;
  }
}
@media (max-width: 575px) {
  .filters {
    margin: auto;
    width: 98%;
    justify-content: center;
  }
  .filters2 {
    margin: auto;
    width: 95%;
    justify-content: center;
  }
  .PieChartNoData {
    width: 250px;
    height: 250px;
  }
  .pieChartNoData_two {
    width: 175px;
    height: 175px;
  }
  .firstChild {
    display: flex;
    flex-direction: column;
  }
  .counts {
    width: 575px;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .childs {
    grid-template-columns: 95%;
  }
  .filterOptions {
    width: 96%;
  }

  .pickerxsmb10{
    margin-bottom: 10px;
  }
  .Seemorebtn{
    padding: 0px 15px;
  }
}
@media (max-width: 350px) {
  .filters {
    margin: auto;
    width: 95%;
    justify-content: center;
  }
  .filters2 {
    margin: auto;
    width: 95%;
    justify-content: center;
  }
  .PieChartNoData {
    width: 200px;
    height: 200px;
  }
  .pieChartNoData_two {
    width: 140px;
    height: 140px;
  }
}


.outerCircle {
background-color: transparent;
/* border: 4px solid rgba(97, 82, 72, 0.9); */
opacity: .9;
/* border-right: 5px solid transparent; */
/* border-left: 5px solid transparent; */
border-radius: 100px;
width: 53px;
height: 53px;
animation: 3s outercircle ;
transition: 1s ease-in-out;

animation-iteration-count: infinite;
background-image: linear-gradient(to right, red , yellow);
margin: 0 auto;
}

@keyframes outercircle {
  0%{
    /* opacity: 0; */
    transform: rotate(0deg);
  }
  100%{
    /* opacity: 1; */
    transform: rotate(360deg);
  }
}
.innerCircle {
  background-color: transparent;
  border: 5px solid rgba(189, 215, 60, 0.6);
  opacity: .9;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-radius: 100px;
  top: -95px;
  width: 92px;
  height: 92px;
  margin: 0 auto;
  position: relative;
  /* animation: 3s innercircle; */
  animation-iteration-count: infinite;
  /* -moz-animation: spinoffPulse 1s infinite linear;
  -webkit-animation: spinoffPulse 1s infinite linear; */
  
  }
  @keyframes innercircle {
    0%{
      opacity: 0;
      transform: rotate(360deg);
    }
    100%{
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  .CountContainer{
    width:50px;
    height: 50px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute;
    top: -43px;
    left: 0;
    right: 0;
    margin: auto; */
  }
  .CountInnerContainer{
    width: 35px;
    height: 35px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border : 2px solid white
  }
  .countBoxText{
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-right: 20px;
  }